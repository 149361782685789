import 'material-icons/iconfont/material-icons.css';
import * as config from "@/config.json";
import axios from 'axios';
import Loading from "vue-loading-overlay";


export default {
  name: "TokensApp",
  components: {
    Loading
  },
  data: () => {
    return {
      cnpj: '',
      isLoading: false,
      appToken: '',
      companyToken: '',
      posToken: '',
      siteToken: '',
      siteUuid: '',
      webhookPassword: '',
      webhookUser: ''
    };
  },

  methods: {
    async generateToken() {
      this.isLoading = true;
      const url = `${config.default.urlBase}/admin/company/tokens`;

      try {
        const response = await axios({
          url,
          method: "POST",
          data: {
            "cnpj": this.cnpj
          },
          headers: {
            authorization: `${config.default.autorizationRetailer}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.appToken = response.data.appToken;
          this.companyToken = response.data.companyToken;
          this.posToken = response.data.posToken;
          this.siteToken = response.data.siteToken;
          this.siteUuid = response.data.siteUuid;
          this.webhookUser = response.data.webhook.user;
          this.webhookPassword = response.data.webhook.password;
        }
      } catch (error) {
        console.log({
          error
        })
        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    }
  },

  async beforeMount() {

  },
};