import axios from "axios";
import 'material-icons/iconfont/material-icons.css';
import * as config from "@/config.json";

export default {
  name: "ResetPassword",
  data: () => {
    return {
      rules: [{
          message: "Minimo de 8 caracteres.",
          regex: /.{8,}/
        },
        {
          message: "Necessário conter uma letra.",
          regex: /[a-z | A-Z]+/
        },
        {
          message: "Necessário conter um número.",
          regex: /[0-9]+/
        },
      ],
      password: "",
      checkPassword: "",
      passwordVisible: false,
      checkPasswordVisible: false,
      submitted: false,
      email: null,
      type: null,
      token: "",
      service: "",
      novoTexto: "",
      app: null,
      customer: null,
    };
  },

  methods: {
    redirect(ref) {
      if (ref == "Message") {
        this.$router.push("/message");
      }
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    returnErrorMessage(errorCode) {
      localStorage.setItem("icon", "error");

      switch (errorCode) {
        case 400:
          return localStorage.setItem(
            "texto",
            "Nenhuma solicitação de alteração de senha foi localizada! Tente refazer a solicitação."
          );
        case 401:
          return localStorage.setItem(
            "texto",
            "Senha já atualizada ou prazo para atualização expirado! Tente refazer a solicitação."
          );
        case 404:
          return localStorage.setItem(
            "texto",
            "E-mail não localizado, não será possível realizar a alteração de senha! Verifique o e-mail informado e tente refazer a solicitação."
          );

        default:
          return localStorage.setItem(
            "texto",
            "Ocorreu um erro na solicitação de alteração de senha. Tente refazer a solicitação."
          );
      }
    },

    buildUrl(type) {
      switch (type) {
        case "user":
          return {
            url: `${config.default.urlBase}/users`,
              authorization: config.default.autorizationRetailer,
          };
        case "customer":
          return {
            url: `${config.default.urlBaseCustomer}/customer`,
              authorization: config.default.authorizationCustomer,
          };

        default:
          return {
            url: `${config.default.urlBaseCustomer}/customer`,
              authorization: config.default.authorizationCustomer,
          };
      }
    },

    async checkToken() {
      const url = `${this.service.url}/reset-password/check-token`;

      try {
        await axios({
          url: url,
          method: "post",
          headers: {
            authorization: `${this.service.authorization}`
          },
          data: {
            email: this.email,
            token: this.token,
            app: this.app,
            customer: this.customer,
          },
        });

      } catch (error) {
        this.returnErrorMessage(error.response.status);
        this.redirect("Message");

        console.error({
          errorMessage: `[CheckToken] ${error}`,
        });
      }
    },

    async postPassword() {
      const url = `${this.service.url}/update-password`;

      try {
        const response = await axios({
          url: url,
          method: "patch",
          headers: {
            authorization: `${this.service.authorization}`
          },
          data: {
            email: this.email,
            token: this.token,
            password: this.password,
            app: this.app,
            customer: this.customer,
          },
        });

        if (response.status == 200) {
          localStorage.setItem("texto", "Senha Atualizada com Sucesso!");
          localStorage.setItem("icon", "success");
          this.redirect("Message");
        }
      } catch (error) {
        this.returnErrorMessage(error.response.status);

        this.redirect("Message");
        this.password = "";
        this.checkPassword = "";

        console.error({
          errorMessage: `[PatchPassword] ${error}`,
        });
      }
    },

    resetPasswords() {
      this.password = "";
      this.checkPassword = "";
      this.submitted = true;
      setTimeout(() => {
        this.submitted = false;
      }, 2000);
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleCheckPasswordVisibility() {
      this.checkPasswordVisible = !this.checkPasswordVisible;
    },
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== "" && this.checkPassword !== "";
    },
    passwordValidation() {
      const errors = [];
      for (const condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return {
          valid: true,
          errors
        };
      } else {
        return {
          valid: false,
          errors
        };
      }
    },
  },

  async beforeMount() {
    localStorage.removeItem("texto");
    localStorage.removeItem("icon");

    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    this.type = this.$route.query.type;
    this.app = parseInt(this.$route.query.app);
    this.customer = parseInt(this.$route.query.customer);

    this.service = this.buildUrl(this.type);

    await this.checkToken();
  },
};