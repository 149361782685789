import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";
import moment from "moment";
import NotFound from "../../components/NotFound.vue"


export default {
  name: "MonitoringView",
  components: {
    Loading,
    NotFound
  },
  data: () => {
    return {
      isLoading: false,
      token: null,
      selectedFilterOption: "",
      posId: 0,
      serieName: "",
      serieStartDate: "",
      serieEndDate: "",
      detailsAlertComplete: [],
      detailsAlert: [],
      fieldsDetailsAlert: [{}],
      perPageAlert: 8,
      filterOptions: [{
          value: "",
          text: "Data",
        },
        {
          value: "1",
          text: "Ultimas 24 Horas",
        },
      ],
      date: moment().format("YYYY-MM-DD"),

      chartOptionsMobile: {
        chart: {
          height: 400,
          width: "50%",
          type: "rangeBar",

          animations: {
            enabled: false,
            easing: 'linear',
            speed: 10,

            animateGradually: {
              enabled: false,
              delay: 10
            },
            dynamicAnimation: {
              enabled: true,
              speed: 10
            },
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            rangeBarGroupRows: true
          }
        },
        colors: [
          '#0B6327', '#CB0000', '#FFD500'
        ],
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,

            align: 'right',
            minWidth: 0,
            maxWidth: 50,
            style: {
              colors: [],
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            // formatter: (value) => {
            //   try {
            //     const index = value.indexOf('|')
            //     if (index >= 0) {
            //       return value.split('|')
            //     }
            //   } catch (error) {
            //     return value
            //   }
            // },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          x: {
            show: false,
            format: 'HH:mm:ss',
          },
          style: {
            fontSize: '8px',
            fontFamily: undefined,
            color: 'silver'
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: -240,
            offsetY: -30,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            try {
              const index = value.indexOf('|')
              if (index >= 0) {
                return value.split('|')
              }
            } catch (error) {
              return value
            }
          },
        }
      },

      chartOptions: {
        chart: {
          height: 500,
          width: "100%",
          type: "rangeBar",

          animations: {
            enabled: false,
            easing: 'linear',
            speed: 10,

            animateGradually: {
              enabled: false,
              delay: 10
            },
            dynamicAnimation: {
              enabled: true,
              speed: 10
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            rangeBarGroupRows: true
          }
        },
        colors: [
          '#0B6327', '#CB0000', '#FFD500'
        ],
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,

            align: 'right',
            minWidth: 0,
            maxWidth: 500,
            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,

            // formatter: (value) => {
            //   console.log({value})
            //   try {
            //     const index = value.indexOf('|')
            //     if (index >= 0) {
            //       return value.split('|')
            //     }
            //   } catch (error) {
            //     return value
            //   }
            // },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          x: {
            show: false,
            format: 'HH:mm:ss',
          },
          style: {
            fontSize: '12px',
            fontFamily: undefined,
            color: 'silver'
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: -240,
            offsetY: -30,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            try {
              const index = value.indexOf('|')
              if (index >= 0) {
                return value.split('|')
              }
            } catch (error) {
              return value
            }
          },
        }
      },
      series: [],
      seriesActive: [],
      seriesInactive: [],
      seriesWarning: [],
      notFoundMessage: '',
      show: false,
      optionsSites: [],
      selectedOptionSites: null,
      tabIndex: 0,
      posStatusComplete: [],
      posStatus: [],
      posStatusFiltered: [],
      fieldsPosStatus: [{}],
      hasPosStatus : false,
      optionsStatusPOS: [],
      selectedOptionsStatusPOS: null,
      siteNamePOS: '',
      posIds: [],
    };
  },
  methods: {
    async clearLocalStorage() {
      localStorage.removeItem('siteIdMonitoringSite')
    },

    async redirect(ref) {
      await this.clearLocalStorage()
      this.$router.push(ref)
    },

    updatePage() {
      this.$router.go();
    },

    async cleanSearch() {
      this.selectedOptionSites = null

      await this.updateChart()
    },

    async dataPointSelection(event, chartContext, config) {
      const serie = config.w.config.series;
      const index = config.seriesIndex;
      const dataPointIndex = config.dataPointIndex;

      const serieMounted = serie[index].data[dataPointIndex];

      this.serieName = serieMounted.x;
      this.posId = serieMounted.posId;
      this.serieStartDate = serieMounted.startDate;
      this.serieEndDate = serieMounted.endDate;


      const posId = new Array(`${this.posId}`)
      await this.getDetails(posId, this.serieStartDate, this.serieEndDate);
      await this.getAllDetails(posId, this.selectedFilterOption, this.date);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async builtData(option, siteId) {
      const data = {}

      if (option == "1") {
        data.filterType = "last24Hours"
      }

      if (option == "") {
        data.filterType = "date"
        data.date = moment(this.date).format("YYYY-MM-DD")
      }

      if (siteId) {
        data.siteId = siteId
      }

      return data
    },

    async changeDate() {
      if (this.selectedFilterOption == "1") {
        return this.updateChart()
      }

      const today = moment(new Date()).format("YYYY-MM-DD")
      if (this.date > today) {
        this.series = []
        return await this.getNotification("Aviso", `A data escolhida deve ser menor ou igual à ${today}!`, "warning");
      }

      this.updateChart();
    },

    buildDataAllDetails(posIds, dateType, date) {
      const data = {
        "posId": posIds,
      }

      if (dateType == '1') {
        data.startDate = moment(Date.now()).subtract(24, 'hours').format("YYYY-MM-DD 00:00:00"),
          data.endDate = moment(Date.now()).format("YYYY-MM-DD 23:59:59")
      } else {
        data.startDate = moment(date).format("YYYY-MM-DD 00:00:00"),
          data.endDate = moment(date).format("YYYY-MM-DD 23:59:59")
      }

      return data
    },

    async getAllDetails(posIds, dateType, date) {
      this.isLoading = true;

      try {
        const url = `${config.default.urlBase}/sites/pos/status/details`;
        const data = this.buildDataAllDetails(posIds, dateType, date)

        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          
          this.hasPosStatus = true

          this.posStatusComplete = response.data;

          let mountedPosStatus = []
          let mountedDuplicatedStatus = []
          await Promise.all(this.posStatusComplete.map(item => {
            mountedPosStatus.push(
            {
              pos: item.posNumber,
              loja: item.siteName,
              data: item.createdAt,
              operação: item.status.operation,
              status: item.status.message
            })
            mountedDuplicatedStatus.push(item.status.message)
          }));

          const uniqueStatus = [... new Set(mountedDuplicatedStatus)]


          this.optionsStatusPOS = await Promise.all(uniqueStatus.map((item, index) => {
            return {
              value: index,
              text: item
            };
          }));

          this.posStatus = mountedPosStatus
          this.posStatusFiltered = mountedPosStatus
          this.siteNamePOS = response.data[0].siteName
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        this.hasPosStatus = false;


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    async cleanOptionsPOSStatus() {
      this.selectedOptionsStatusPOS = null 
      this.posStatusFiltered = this.posStatus
    },


    async filterPOSStatus(selectedOptionsStatusPOS) {
      const optionsStatus = this.optionsStatusPOS
      const status = optionsStatus[selectedOptionsStatusPOS].text 

      this.posStatusFiltered = this.posStatus.filter(item => item.status == status)
    },

    async getDetails(posId, serieStartDate, serieEndDate) {
      this.isLoading = true;

      try {
        const url = `${config.default.urlBase}/sites/pos/status/details`;
        const data = {
          "posId": posId,
          "startDate": moment(serieStartDate).format("YYYY-MM-DD HH:mm:ss"),
          "endDate": moment(serieEndDate).format("YYYY-MM-DD HH:mm:ss")
        }

        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.detailsAlertComplete = response.data;

          this.detailsAlert = await Promise.all(this.detailsAlertComplete.map(item => {
            return {
              data: item.createdAt,
              operação: item.status.operation,
              status: item.status.message
            };
          }));

          this.$bvModal.show('detailsAlert');
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro atualizar o status do monitoramento! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByUserType() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/user/type`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsSites = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsSites.length == 1) {
            this.selectedOptionSites = this.optionsSites[0].value;
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async updateChart() {
      this.isLoading = true;
      this.hasPosStatus = false;
      const url = `${config.default.urlBase}/sites/pos/status`;
      const data = await this.builtData(this.selectedFilterOption, this.selectedOptionSites);

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status === 200) {
          this.isLoading = false;
          this.series = response.data.timeLine;
          this.seriesActive = this.series[0].data;
          this.seriesInactive = this.series[1].data;
          this.seriesWarning = this.series[2].data;

          if (this.seriesActive.length <= 0 && this.seriesInactive.length <= 0 && this.seriesWarning.length <= 0) {
            this.notFoundMessage = 'Nenhum POS foi identificado !';
            this.show = true;
          }

          this.posIds = response.data.posIds
          const posIds = new Array(this.posIds.join(','))
          this.getAllDetails(posIds, this.selectedFilterOption, this.date);
        } else {
          this.show = false;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;
        this.series = [];
        this.show = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro atualizar o status do monitoramento! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );

        }
      }
    },

    async readSiteParams() {
      if (this.$route.params.siteId) {
        localStorage.setItem('siteIdMonitoringSite', this.$route.params.siteId)
      }

      this.selectedOptionSites = parseInt(localStorage.getItem('siteIdMonitoringSite'))
      await this.updateChart()
      await this.clearLocalStorage()
    },
  },

  async beforeMount() {
    this.token = localStorage.getItem("token");
    await this.getSitesByUserType();
    await this.readSiteParams();
    await this.updateChart();
  },
};