import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";

export default {
  name: "ManagerUserPermissions",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      token: null,
      userId: null,
      userName: '',
      allPermissionsMobile: [],
      allPermissionsWeb: [],
      values: [],
      page: ''
    };
  },

  methods: {
    redirect() {
      const ref = localStorage.getItem("redirectUserPermissions") ? localStorage.getItem("redirectUserPermissions") : "users-list";
      localStorage.removeItem("redirectUserPermissions");
      this.$router.push(ref);
    },

    async readParamsUserPermission() {
      this.userId = this.$route.params.userId;
      this.userName = this.$route.params.userName;
      this.page = this.$route.params.page
    },


    async getAllPremissions(userId) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/users/${userId}/permissions/all`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.allPermissionsMobile = response.data.mobile;
          this.allPermissionsWeb = response.data.web;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async buildDataUserPermissions() {
      const permissionsActiveMobile = this.allPermissionsMobile
        .filter(permission => permission.isActive)

      const permissionsActiveWeb = this.allPermissionsWeb
        .filter(permission => permission.isActive)

      const permissions = [];
      permissions.push(...permissionsActiveMobile.map(permission => permission.userPermissionId));
      permissions.push(...permissionsActiveWeb.map(permission => permission.userPermissionId));


      return {
        userId: this.userId,
        permissions: permissions
      }
    },

    async createOrUpdate() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/users/permissions`;
      const data = await this.buildDataUserPermissions();

      try {
        const response = await axios({
          url,
          method: "put",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            `As permissões do Usuário ${this.userName} foram atualizadas!`,
            "Sucesso",
            "success"
          );

          if (this.page == 'usersManager') {
            this.$router.push('/users-manager')
          } else if (this.page == 'usersList') {
            this.$router.push('/users-list')
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao criar ou atualizar as permissões! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.readParamsUserPermission();

    await this.getAllPremissions(this.userId)
    this.isLoading = false;
  },
};