export default {
  name: "MessageResetPassword",

  data: () => {
    return {
      texto: "",
      icon: "",
    };
  },

  methods: {
    redirect() {
      this.$router.push("/login");
    },
  },

  async beforeMount() {
    this.texto = localStorage.getItem("texto");
    this.icon = localStorage.getItem("icon");
  },
};