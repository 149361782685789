import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/assets/scss/style.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSimpleAlert from "vue-simple-alert";
import '@fortawesome/fontawesome-free/css/all.css'
import VueToastify from "vue-toastify";
import SweetAlertIcons from "vue-sweetalert-icons";
import VueMask from 'v-mask'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import money from 'v-money';

Vue.use(money, {precision: 4})
Vue.use(SweetAlertIcons);
Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToastify);
Vue.use(Loading);
Vue.use(VueMask);
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2);

Vue.component('ApexChart', VueApexCharts)

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
