<template>
  <div>
    <b-card v-if="show" class="notFound">
      <img src="../../public/notFound.png" alt="" width="150px"/>
      <h1 style="font-size: 80px">Oops!</h1>
      <h3 class="message">
        {{ message }}
      </h3>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["message", "show"],
};
</script>

<style scoped>
.notFound :hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
}

.notFound {
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 100, 1);
  margin-top: 15px;
}

</style>