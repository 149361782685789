import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue";

export default {
  name: "SearchSpeech",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },
  data: () => {
    return {
      isLoading: false,
      filter: '',
      selectedOption: null,
      option: [{
          value: null,
          text: "Selecione um filtro",
        },
        {
          value: 1,
          text: "Número do Totem",
        },
        {
          value: 2,
          text: "Token do Totem",
        },
      ],
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      items: [],
      fields: [{
          key: "id",
          label: "ID",
        },
        {
          key: "createdAt",
          label: "Data",
        },
        {
          key: "number",
          label: "Número Totem",
        },
        {
          key: "fullName",
          label: "Totem",
        },
        {
          key: "token",
          label: "Token Totem",
        },
        {
          key: "speechDescription",
          label: "Descrição da Fala",
        },
        {
          key: "speechText",
          label: "Texto da Fala",
        },
        {
          key: "action",
          label: "",
        },
      ],
      currentPage: 1,
      perPage: 10,
      pagination: {},
      paginationRange: 6,
      pagePagination: null,
    };
  },
  methods: {

    async clearLocalStoragePage() {
      localStorage.removeItem('currentPageSearchSpeechs');
      localStorage.removeItem('selectedOptionCompanySearchSpeechs');
      localStorage.removeItem('selectedOptionSiteSearchSpeechs');
      localStorage.removeItem('selectedOptionFilterSearchSpeechs');
      localStorage.removeItem('filterSearchSpeechs');
    },

    async redirect(ref) {
      if (ref == "bot-bound") {
        localStorage.removeItem('redirectBotBound');
        localStorage.setItem('redirectBotBound', '/bot-bounded');
      } else {
        await this.clearLocalStoragePage();
      }

      this.$router.push(ref);
    },

    async navigate(page) {
      this.searchSpeech(page);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async changeFilter() {
      if (this.selectedOption == null) {
        await this.searchSpeech(this.currentPage)
      }
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value;
            }
          }

          if (this.selectedOptionCompany) {
            this.getSitesByCompany(this.selectedOptionCompany);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.sites = response.data;

          this.optionsSite = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsSite.length == 1) {
            if (this.selectedOptionSite) {
              this.selectedOptionSite = this.optionsSite[0].value;
            }
          }


          if (this.selectedOptionSite) {
            if (!this.pagePagination) {
              await this.searchSpeech(this.currentPage);
            } else {
              await this.navigate(this.pagePagination)
            }
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsSite = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    },

    buildParams(companyId, siteId, option, page) {
      if (this.selectedOptionCompany != null) {
        let params = new Object();

        if (companyId != null) {
          params.companyId = companyId
        } else {
          return null
        }

        if (siteId != null) {
          params.siteId = siteId
        } else {
          return null
        }

        if (option == 1) {
          params.posNumber = this.filter
        }

        if (option == 2) {
          params.posToken = this.filter
        }

        params.pageSize = this.perPage;
        params.page = page;

        return params;
      } else {
        return null
      }
    },

    async searchSpeech(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/speechs/company/search`;
      const params = await this.buildParams(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOption, page);

      localStorage.setItem('currentPageSearchSpeechs', page);
      localStorage.setItem('selectedOptionCompanySearchSpeechs', this.selectedOptionCompany);
      localStorage.setItem('selectedOptionSiteSearchSpeechs', this.selectedOptionSite);
      localStorage.setItem('selectedOptionFilterSearchSpeechs', this.selectedOption);
      localStorage.setItem('filterSearchSpeechs', this.filter);

      try {
        const response = await axios({
          url,
          method: "get",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.items = response.data.items;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.items = [];
        this.pagination = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async deleteSpeechBounded(speech) {
      await this.destroySpeechsBounded(speech.item.posSpeechId);
    },

    async deleteSpeechBoundedMobile(item) {
      await this.destroySpeechsBounded(item.posSpeechId);
    },

    async destroySpeechsBounded(speedchId) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/speech/${speedchId}/unbound`;

      try {
        const response = await axios({
          url,
          method: "delete",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            `Vinculo excluído!`,
            "Sucesso",
            "success"
          );

          this.searchSpeech(this.currentPage);

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.items = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao excluir o vinculo da fala com o totem! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    this.pagePagination = localStorage.getItem('currentPageSearchSpeechs');
    this.selectedOptionCompany = localStorage.getItem('selectedOptionCompanySearchSpeechs');
    this.selectedOptionSite = localStorage.getItem('selectedOptionSiteSearchSpeechs');
    this.selectedOption = localStorage.getItem('selectedOptionFilterSearchSpeechs');
    this.filter = localStorage.getItem('filterSearchSpeechs');

    this.getCompanies();
    this.isLoading = false;
  },
};