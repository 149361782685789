import Loading from "vue-loading-overlay";
import Datepicker from "vuejs-datepicker";
import Timeselector from "vue-timeselector";
import moment from "moment";

import {
  Money
} from 'v-money'


import {
  ptBR
} from "vuejs-datepicker/dist/locale";
import {
  TheMask
} from "vue-the-mask";


export default {
  name: "CoupnInformation",
  components: {
    Loading,
    Datepicker,
    Timeselector,
    TheMask,
    moment,
    Money
  },

  data: () => {
    return {
      token: "",
      isLoading: false,

      expira: false,
      push: false,
      titulo: "",
      descricao: "",
      valorDesconto: null,
      dataInicial: null,
      dataFinal: null,
      ptBR: ptBR,
      horaInicial: "00:00",
      horaFinal: "23:59",

      selectedOption: null,
      options: [{
          value: null,
          text: "Selecione o tipo do desconto"
        },
        {
          value: 1,
          text: "Valor"
        },
        {
          value: 2,
          text: "Porcentagem"
        },
      ],

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },

      min: ''
    };
  },

  methods: {
    async redirect() {
      const ref = localStorage.getItem("redirectDiscount") ? localStorage.getItem("redirectDiscount") : "home";

      localStorage.removeItem("redirectDiscount");
      this.$router.push(ref);
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async reloadStartDate() {
      this.dataInicial = moment(new Date()).format("YYYY-MM-DD");
      this.horaInicial = '00:00';
    },

    reloadEndDate() {
      this.dataFinal = moment().format("YYYY-MM-DD");
      this.horaFinal = '23:59';
    },

    async minStartDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      this.min =  moment(today).format("YYYY-MM-DD HH:mm:ss");

    },

    async getLocalStorage() {
      this.titulo = localStorage.getItem("titleCouponDiscount") ? localStorage.getItem("titleCouponDiscount") : "";
      this.descricao = localStorage.getItem("descriptionCouponDiscount") ? localStorage.getItem("descriptionCouponDiscount") : "";

      this.dataInicial = localStorage.getItem("startDateCouponDiscount");
      this.horaInicial = localStorage.getItem("startTimeCouponDiscount") ? localStorage.getItem("startTimeCouponDiscount") : "00:00";

      this.expira = localStorage.getItem("expiry") == 'true' ? true : localStorage.getItem("expiry") == 'false' ? false : false
      this.push = localStorage.getItem("push") == 'true' ? true : localStorage.getItem("push") == 'false' ? false : false

      this.dataFinal = localStorage.getItem("expiryAtCouponDiscount");
      this.horaFinal = localStorage.getItem("endTimeCouponDiscount") ? localStorage.getItem("endTimeCouponDiscount") : "23:59";

      this.selectedOption = localStorage.getItem("typeCouponDiscount") ? localStorage.getItem("typeCouponDiscount") : null;
      this.valorDesconto = parseFloat(localStorage.getItem("valueCouponDiscount") ? localStorage.getItem("valueCouponDiscount") : null);
    },

    async rowHandler() {
      localStorage.removeItem("push");
      localStorage.setItem("push", this.push);

      await this.minStartDate();

      if ((this.dataInicial && this.dataFinal) && (this.dataFinal < this.dataInicial)) {
        this.getNotification(
          "Aviso",
          "A data final deve ser maior que a data inicial!",
          "warning"
        );
        this.$emit("can-continue", {
          value: false
        });
      } else if ((!this.valorDesconto) || (this.valorDesconto == null) || (!this.titulo) || (!this.descricao) || (!this.dataInicial) || (!this.horaInicial)) {
        this.$emit("can-continue", {
          value: false
        });
      } else {
        this.$emit("can-continue", {
          value: true
        });
      }

      localStorage.setItem("startDateCouponDiscount", this.dataInicial);
      localStorage.setItem("startTimeCouponDiscount", this.horaInicial);
      localStorage.setItem("titleCouponDiscount", this.titulo);
      localStorage.setItem("descriptionCouponDiscount", this.descricao);
      localStorage.setItem("expiryAtCouponDiscount", this.dataFinal);
      localStorage.setItem("endTimeCouponDiscount", this.horaFinal);
      localStorage.setItem("typeCouponDiscount", this.selectedOption);
      localStorage.setItem("valueCouponDiscount", this.valorDesconto);
      localStorage.setItem("expiry", this.expira);
      localStorage.setItem("push", this.push);

    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  computed: {
    tituloState() {
      return this.titulo ? true : false
    },

    descricaoState() {
      return this.descricao ? true : false
    },

    valorDescontoState() {
      return this.valorDesconto ? true : false
    },

  },

  async beforeMount() {
    this.isLoading = true;

    this.dataInicial = moment().format("YYYY-MM-DD");
    this.dataFinal = moment().add(1, "days").format("YYYY-MM-DD");

    await this.minStartDate();

    this.token = localStorage.getItem("token");

    this.expira = false;

    await this.getLocalStorage();

    this.isLoading = false;
  },
};