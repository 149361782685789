import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";


export default {
  name: "GatewayDocument",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      token: "",
      items: [],
      fields: [{
          key: "appName",
          label: "Aplicativo"
        },
        {
          key: "gatewayName",
          label: "Gateway"
        },
        {
          key: "gatewayUrl",
          label: "Url"
        },
        {
          key: "gatewayAuthorizationId",
          label: "ID Autorização"
        },
        {
          key: "gatewayAuthorizationToken",
          label: "Token Autorização"
        },
        {
          key: "gatewayIsDefault",
          label: "Default"
        },
        {
          key: "gatewayIsCustomerValidate",
          label: "Valida Cliente"
        },
        {
          key: "gatewayIsCompanyValidate",
          label: "Valida Empresa"
        },
        {
          key: "gatewayBalance",
          label: "Saldo Disponível"
        },
        {
          key: "gatewayRetries",
          label: "Tentativas"
        },
        {
          key: "gatewayCustomerQueryType",
          label: "Tipo Consulta Cliente"
        },
        {
          key: "gatewayCompanyQueryType",
          label: "Tipo Consulta Empresa"
        },
      ],
      currentPage: 1,
      perPage: 10,
    };
  },

  methods: {
    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },



    async getDocumentGateways() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers-document-gateways`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.items = response.data;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.items = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getDocumentGateways();
    this.isLoading = false;
  },
};