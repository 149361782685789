import Loading from "vue-loading-overlay";

export default {
  name: "SetupView",
  components: {
    Loading
  },
  data: () => {
    return {
      isLoading: false,
    }
  },

  methods: {
    redirect(ref) {
      this.$router.push(ref);
    }
  }
}