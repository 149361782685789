import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";

export default {
  name: "FindSpeech",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      items: [],
      fields: [{
          key: "id",
          label: "ID",
        },
        {
          key: "createdAt",
          label: "Data",
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "text",
          label: "Fala",
        },
        {
          key: "action",
          label: "",
        },
      ],
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
    redirect(ref) {
      if (ref == "bot") {
        localStorage.removeItem('redirectBot');
        localStorage.setItem('redirectBot', '/bot-speechs');
      }

      this.$router.push(ref);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getSpeechs() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/speechs`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.items = response.data;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.options = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async deleteSpeech(speech) {
      await this.destroySpeechs(speech.item.id);
    },

    async deleteSpeechMobile(item) {
      await this.destroySpeechs(item.id);
    },

    async destroySpeechs(id) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/speech/${id}`;

      try {
        const response = await axios({
          url,
          method: "delete",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            `Fala excluída!`,
            "Sucesso",
            "success"
          );

          this.getSpeechs();

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.options = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.getSpeechs();
    this.isLoading = false;
  },
};